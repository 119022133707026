import {FormControl, FormControlLabel, FormGroup, MenuItem, Popover, type SelectChangeEvent} from "@mui/material";
import Select from "@mui/material/Select";
import {languages} from "../../pages/appsync/appsync";
import {getUrlAppsync} from "../../utils/utils";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {
    setDisplaySpeakerName,
    setHideOriginalText,
    setStopAutoScroll, setSubtitleFontsize,
    setSubtitleLanguage,
    setSubtitleTranslationLanguage, setTranslateTranslatedSentences
} from "../../services/sora/soraSlice";
import {useTranslation} from "react-i18next";
import {type PopoverOrigin} from "@mui/material/Popover/Popover";
import {languages as spokenLanguages} from "./subTitleDialog";
import {QRCodeSVG} from "qrcode.react";

interface Props {
    openShowSubtitleTranslation: boolean
    handleCloseShowSubtitleTranslation: any
    anchorShowSubtitleTranslation: Element | null
    isAppsyncPage?: boolean
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    channelId: string
}

export function PopoverSubtitleSetting (props: Props) {
    const {
        subtitleTranslationLanguage,
        isHideOriginalText,
        isStopAutoScroll,
        subtitleLanguage,
        isDisplaySpeakerName,
        isTranslateTranslatedSentences,
        subtitleFontsize
    } = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [language, setLanguage] = React.useState(subtitleLanguage);

    const handleChangeSubtitleTranslationLanguage = (event: SelectChangeEvent) => {
        dispatch(setSubtitleTranslationLanguage(event.target.value));
    };

    const handleChangeHideOriginalText = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setHideOriginalText(event.target.checked));
    };

    const handleStopAutoScroll = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setStopAutoScroll(event.target.checked));
    };

    const handleChangeDisplaySpeakerName = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setDisplaySpeakerName(event.target.checked));
        localStorage.setItem("isDisplaySpeakerName", String(event.target.checked));
    };

    const handleChangeTranslateTranslatedSentences = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setTranslateTranslatedSentences(event.target.checked));
        localStorage.setItem("isTranslateTranslatedSentences", String(event.target.checked));
    };

    const handleChangeSubtitleFontsize = (event: SelectChangeEvent) => {
        let fontsize = Number.parseInt(event.target.value);
        if (!fontsize) {
            fontsize = 16;
        }
        dispatch(setSubtitleFontsize(fontsize));
    };

    const handleChangeLanguage = (event: SelectChangeEvent) => {
        const language = event.target.value;
        setLanguage(language);
        if (subtitleLanguage !== language) {
            dispatch(setSubtitleLanguage(language));
        }
    };

    return <Popover
        open={props.openShowSubtitleTranslation}
        onClose={props.handleCloseShowSubtitleTranslation}
        anchorEl={props.anchorShowSubtitleTranslation}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
    >
        <div className="translation-language">
            <div hidden={props.isAppsyncPage} style={{marginBottom: 15}}>
                <label htmlFor="spoken-languages">{t("The language you speak")}</label>
                <FormControl fullWidth margin="dense" size="small">
                    <Select
                        labelId="demo-simple-select-label"
                        id="spoken-languages"
                        value={language}
                        onChange={handleChangeLanguage}
                    >
                        {
                            spokenLanguages.map((language, index) => {
                                return <MenuItem value={language.code} key={index}>{language.name}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <label htmlFor="select-translation-language">{t("Please select your translation language")}</label>
            <FormControl margin="dense" size="small" fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subtitleTranslationLanguage}
                    onChange={handleChangeSubtitleTranslationLanguage}
                >
                    <MenuItem value="0">No translation</MenuItem>
                    {
                        languages.map((language, index) => {
                            return <MenuItem value={language.language} key={index}>{language.name}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
            <label htmlFor="size-setting-of-subtitles" style={{marginTop: 15}}>{t("Size setting of subtitles")}</label>
            <FormControl margin="dense" size="small" fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="size-setting-of-subtitles"
                    value={subtitleFontsize.toString()}
                    onChange={handleChangeSubtitleFontsize}
                >
                    <MenuItem value="12">{t("Small")}</MenuItem>
                    <MenuItem value="16">{t("Standard")}</MenuItem>
                    <MenuItem value="20">{t("Big")}</MenuItem>
                </Select>
            </FormControl>
            <div className="url-appsync-qr-code">
                <a style={{marginTop: 15, display: "block"}} href={getUrlAppsync(props.channelId)} target="_blank"
                   rel="noreferrer">{t("Open subtitle channel")}</a>
                <QRCodeSVG style={{marginTop: 8}} value={getUrlAppsync(props.channelId)}/>
            </div>
            <FormGroup style={{marginTop: 10}}>
                <FormControlLabel
                    control={<Checkbox checked={isHideOriginalText} onChange={handleChangeHideOriginalText}/>}
                    label={t("Do not display the original text before translation")}/>
                <FormControlLabel control={<Checkbox checked={isStopAutoScroll} onChange={handleStopAutoScroll}/>}
                                  label={t("Stop auto-scrolling")}/>
                {
                    props.isAppsyncPage &&
                    <FormControlLabel control={<Checkbox checked={isDisplaySpeakerName} onChange={handleChangeDisplaySpeakerName}/>}
                                      label={t("Display the speaker name")}/>
                }
                {
                    !props.isAppsyncPage &&
                    <FormControlLabel control={<Checkbox checked={isTranslateTranslatedSentences} onChange={handleChangeTranslateTranslatedSentences}/>}
                                      label={t("Translate the translated sentences")}/>
                }
            </FormGroup>
        </div>
    </Popover>
        ;
}
