import {useEffect} from "react";
import "./emojiContainer.css";
import {useSelector} from "react-redux";
import type {RootState} from "../../store";

let intervalCreateEmoji: any;

export default function EmojiContainer () {
    const {emojiParticles} = useSelector((state: RootState) => state.sora);

    useEffect(() => {
        createFloatingEmojis();

        return () => {
            clearInterval(intervalCreateEmoji);
        };
    }, [emojiParticles]);

    const createFloatingEmojis = () => {
        clearInterval(intervalCreateEmoji);
        const emojiContainer = document.getElementById("emoji-container");
        if (!emojiContainer) {
            return;
        }

        function generateEmoji () {
            if (!emojiParticles.emojiType) {
                return;
            }

            const emojiElement = document.createElement("div");
            emojiElement.classList.add("emoji-particles");
            emojiElement.innerHTML = `<img src="/img/emoji/${emojiParticles.emojiType}.gif" alt="${emojiParticles.emojiType}" />`;

            // Set random horizontal position
            emojiElement.style.left = (Math.random() * 100).toString() + "vw";

            // Set random animation duration
            emojiElement.style.animationDuration = (Math.random() * 3 + 1).toString() + "s";
            emojiElement.style.width = (Math.random() * 2 + 1).toString() + "em";

            emojiContainer?.appendChild(emojiElement);

            // Remove the emoji after animation ends
            setTimeout(() => {
                emojiElement.remove();
            }, 5000);
        }

        // Create new emojis at intervals
        intervalCreateEmoji = setInterval(generateEmoji, 200);
    };

    return <div id="emoji-container"></div>;
}
