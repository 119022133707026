import {Amplify} from "aws-amplify";
import {onMessageSent} from "../../graphql/subscriptions";
import React, {useEffect, useState} from "react";
import {generateClient} from "aws-amplify/api";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {
    setContentSubTitle,
    setTranslateTranslatedSentences,
    type TranscriptDeepGram
} from "../../services/sora/soraSlice";
import axios from "axios";
import "./appsync.css";
import SettingsIcon from "@mui/icons-material/Settings";
import {PopoverSubtitleSetting} from "../../components/subTitle/popoverSubtitleSetting";
import SubtitleItem from "../../components/subTitle/subtitleItem";
import {decodeHTMLandURL, endsWithPeriodOrFullWidthPeriod} from "../../utils/utils";
import SubTitleDialog from "../../components/subTitle/subTitleDialog";

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const channel = params.get("channel") || "";

interface NewMsg {
    id: string
    content: string
    connectionId: string
    channel: string
    resultsId: string
    language: string
    isFinal: boolean
    __typename: string
}

interface SignalingNotifyMetadata {
    name: string
}

interface AppsyncApiKeyResponse {
    api_key: string
}

export const languages = [
    {
        language: "ab",
        name: "Abkhaz"
    },
    {
        language: "ace",
        name: "Acehnese"
    },
    {
        language: "ach",
        name: "Acholi"
    },
    {
        language: "aa",
        name: "Afar"
    },
    {
        language: "af",
        name: "Afrikaans"
    },
    {
        language: "sq",
        name: "Albanian"
    },
    {
        language: "alz",
        name: "Alur"
    },
    {
        language: "am",
        name: "Amharic"
    },
    {
        language: "ar",
        name: "Arabic"
    },
    {
        language: "hy",
        name: "Armenian"
    },
    {
        language: "as",
        name: "Assamese"
    },
    {
        language: "av",
        name: "Avar"
    },
    {
        language: "awa",
        name: "Awadhi"
    },
    {
        language: "ay",
        name: "Aymara"
    },
    {
        language: "az",
        name: "Azerbaijani"
    },
    {
        language: "ban",
        name: "Balinese"
    },
    {
        language: "bal",
        name: "Baluchi"
    },
    {
        language: "bm",
        name: "Bambara"
    },
    {
        language: "bci",
        name: "Baoulé"
    },
    {
        language: "ba",
        name: "Bashkir"
    },
    {
        language: "eu",
        name: "Basque"
    },
    {
        language: "btx",
        name: "Batak Karo"
    },
    {
        language: "bts",
        name: "Batak Simalungun"
    },
    {
        language: "bbc",
        name: "Batak Toba"
    },
    {
        language: "be",
        name: "Belarusian"
    },
    {
        language: "bem",
        name: "Bemba"
    },
    {
        language: "bn",
        name: "Bengali"
    },
    {
        language: "bew",
        name: "Betawi"
    },
    {
        language: "bho",
        name: "Bhojpuri"
    },
    {
        language: "bik",
        name: "Bikol"
    },
    {
        language: "bs",
        name: "Bosnian"
    },
    {
        language: "br",
        name: "Breton"
    },
    {
        language: "bg",
        name: "Bulgarian"
    },
    {
        language: "bua",
        name: "Buryat"
    },
    {
        language: "yue",
        name: "Cantonese"
    },
    {
        language: "ca",
        name: "Catalan"
    },
    {
        language: "ceb",
        name: "Cebuano"
    },
    {
        language: "ch",
        name: "Chamorro"
    },
    {
        language: "ce",
        name: "Chechen"
    },
    {
        language: "ny",
        name: "Chichewa"
    },
    {
        language: "zh",
        name: "Chinese (Simplified)"
    },
    {
        language: "zh-TW",
        name: "Chinese (Traditional)"
    },
    {
        language: "chk",
        name: "Chuukese"
    },
    {
        language: "cv",
        name: "Chuvash"
    },
    {
        language: "co",
        name: "Corsican"
    },
    {
        language: "crh",
        name: "Crimean Tatar"
    },
    {
        language: "hr",
        name: "Croatian"
    },
    {
        language: "cs",
        name: "Czech"
    },
    {
        language: "da",
        name: "Danish"
    },
    {
        language: "fa-AF",
        name: "Dari"
    },
    {
        language: "din",
        name: "Dinka"
    },
    {
        language: "dv",
        name: "Divehi"
    },
    {
        language: "doi",
        name: "Dogri"
    },
    {
        language: "dov",
        name: "Dombe"
    },
    {
        language: "nl",
        name: "Dutch"
    },
    {
        language: "dyu",
        name: "Dyula"
    },
    {
        language: "dz",
        name: "Dzongkha"
    },
    {
        language: "en",
        name: "English"
    },
    {
        language: "eo",
        name: "Esperanto"
    },
    {
        language: "et",
        name: "Estonian"
    },
    {
        language: "ee",
        name: "Ewe"
    },
    {
        language: "fo",
        name: "Faroese"
    },
    {
        language: "fj",
        name: "Fijian"
    },
    {
        language: "tl",
        name: "Filipino"
    },
    {
        language: "fi",
        name: "Finnish"
    },
    {
        language: "fon",
        name: "Fon"
    },
    {
        language: "fr",
        name: "French"
    },
    {
        language: "fy",
        name: "Frisian"
    },
    {
        language: "fur",
        name: "Friulian"
    },
    {
        language: "ff",
        name: "Fulfulde"
    },
    {
        language: "gaa",
        name: "Ga"
    },
    {
        language: "gl",
        name: "Galician"
    },
    {
        language: "lg",
        name: "Ganda"
    },
    {
        language: "ka",
        name: "Georgian"
    },
    {
        language: "de",
        name: "German"
    },
    {
        language: "el",
        name: "Greek"
    },
    {
        language: "gn",
        name: "Guarani"
    },
    {
        language: "gu",
        name: "Gujarati"
    },
    {
        language: "ht",
        name: "Haitian Creole"
    },
    {
        language: "cnh",
        name: "Hakha Chin"
    },
    {
        language: "ha",
        name: "Hausa"
    },
    {
        language: "haw",
        name: "Hawaiian"
    },
    {
        language: "iw",
        name: "Hebrew"
    },
    {
        language: "hil",
        name: "Hiligaynon"
    },
    {
        language: "hi",
        name: "Hindi"
    },
    {
        language: "hmn",
        name: "Hmong"
    },
    {
        language: "hu",
        name: "Hungarian"
    },
    {
        language: "hrx",
        name: "Hunsrik"
    },
    {
        language: "iba",
        name: "Iban"
    },
    {
        language: "is",
        name: "Icelandic"
    },
    {
        language: "ig",
        name: "Igbo"
    },
    {
        language: "ilo",
        name: "Iloko"
    },
    {
        language: "id",
        name: "Indonesian"
    },
    {
        language: "ga",
        name: "Irish Gaelic"
    },
    {
        language: "it",
        name: "Italian"
    },
    {
        language: "jam",
        name: "Jamaican Patois"
    },
    {
        language: "ja",
        name: "Japanese"
    },
    {
        language: "jw",
        name: "Javanese"
    },
    {
        language: "kac",
        name: "Jingpo"
    },
    {
        language: "kl",
        name: "Kalaallisut"
    },
    {
        language: "kn",
        name: "Kannada"
    },
    {
        language: "kr",
        name: "Kanuri"
    },
    {
        language: "pam",
        name: "Kapampangan"
    },
    {
        language: "kk",
        name: "Kazakh"
    },
    {
        language: "kha",
        name: "Khasi"
    },
    {
        language: "km",
        name: "Khmer"
    },
    {
        language: "cgg",
        name: "Kiga"
    },
    {
        language: "kg",
        name: "Kikongo"
    },
    {
        language: "rw",
        name: "Kinyarwanda"
    },
    {
        language: "ktu",
        name: "Kituba"
    },
    {
        language: "trp",
        name: "Kokborok"
    },
    {
        language: "kv",
        name: "Komi"
    },
    {
        language: "gom",
        name: "Konkani"
    },
    {
        language: "ko",
        name: "Korean"
    },
    {
        language: "kri",
        name: "Krio"
    },
    {
        language: "ku",
        name: "Kurdish (Kurmanji)"
    },
    {
        language: "ckb",
        name: "Kurdish (Sorani)"
    },
    {
        language: "ky",
        name: "Kyrgyz"
    },
    {
        language: "lo",
        name: "Lao"
    },
    {
        language: "ltg",
        name: "Latgalian"
    },
    {
        language: "la",
        name: "Latin"
    },
    {
        language: "lv",
        name: "Latvian"
    },
    {
        language: "lij",
        name: "Ligurian"
    },
    {
        language: "li",
        name: "Limburgan"
    },
    {
        language: "ln",
        name: "Lingala"
    },
    {
        language: "lt",
        name: "Lithuanian"
    },
    {
        language: "lmo",
        name: "Lombard"
    },
    {
        language: "luo",
        name: "Luo"
    },
    {
        language: "lb",
        name: "Luxembourgish"
    },
    {
        language: "mk",
        name: "Macedonian"
    },
    {
        language: "mad",
        name: "Madurese"
    },
    {
        language: "mai",
        name: "Maithili"
    },
    {
        language: "mak",
        name: "Makassar"
    },
    {
        language: "mg",
        name: "Malagasy"
    },
    {
        language: "ms",
        name: "Malay"
    },
    {
        language: "ms-Arab",
        name: "Malay (Jawi)"
    },
    {
        language: "ml",
        name: "Malayalam"
    },
    {
        language: "mt",
        name: "Maltese"
    },
    {
        language: "mam",
        name: "Mam"
    },
    {
        language: "gv",
        name: "Manx"
    },
    {
        language: "mi",
        name: "Maori"
    },
    {
        language: "mr",
        name: "Marathi"
    },
    {
        language: "mh",
        name: "Marshallese"
    },
    {
        language: "mwr",
        name: "Marwadi"
    },
    {
        language: "mfe",
        name: "Mauritian Creole"
    },
    {
        language: "chm",
        name: "Meadow Mari"
    },
    {
        language: "mni-Mtei",
        name: "Meiteilon (Manipuri)"
    },
    {
        language: "min",
        name: "Minang"
    },
    {
        language: "lus",
        name: "Mizo"
    },
    {
        language: "mn",
        name: "Mongolian"
    },
    {
        language: "my",
        name: "Myanmar (Burmese)"
    },
    {
        language: "nhe",
        name: "Nahuatl (Eastern Huasteca)"
    },
    {
        language: "ndc-ZW",
        name: "Ndau"
    },
    {
        language: "nr",
        name: "Ndebele (South)"
    },
    {
        language: "new",
        name: "Nepalbhasa (Newari)"
    },
    {
        language: "ne",
        name: "Nepali"
    },
    {
        language: "bm-Nkoo",
        name: "NKo"
    },
    {
        language: "nso",
        name: "Northern Sotho"
    },
    {
        language: "no",
        name: "Norwegian"
    },
    {
        language: "nus",
        name: "Nuer"
    },
    {
        language: "oc",
        name: "Occitan"
    },
    {
        language: "or",
        name: "Odia (Oriya)"
    },
    {
        language: "om",
        name: "Oromo"
    },
    {
        language: "os",
        name: "Ossetian"
    },
    {
        language: "pag",
        name: "Pangasinan"
    },
    {
        language: "pap",
        name: "Papiamento"
    },
    {
        language: "ps",
        name: "Pashto"
    },
    {
        language: "fa",
        name: "Persian"
    },
    {
        language: "pl",
        name: "Polish"
    },
    {
        language: "pt",
        name: "Portuguese"
    },
    {
        language: "pt-PT",
        name: "Portuguese (Portugal)"
    },
    {
        language: "pa",
        name: "Punjabi"
    },
    {
        language: "pa-Arab",
        name: "Punjabi (Shahmukhi)"
    },
    {
        language: "kek",
        name: "Q'eqchi'"
    },
    {
        language: "qu",
        name: "Quechua"
    },
    {
        language: "rom",
        name: "Romani"
    },
    {
        language: "ro",
        name: "Romanian"
    },
    {
        language: "rn",
        name: "Rundi"
    },
    {
        language: "ru",
        name: "Russian"
    },
    {
        language: "se",
        name: "Sami (North)"
    },
    {
        language: "sm",
        name: "Samoan"
    },
    {
        language: "sg",
        name: "Sango"
    },
    {
        language: "sa",
        name: "Sanskrit"
    },
    {
        language: "sat-Latn",
        name: "Santali"
    },
    {
        language: "gd",
        name: "Scots Gaelic"
    },
    {
        language: "sr",
        name: "Serbian"
    },
    {
        language: "st",
        name: "Sesotho"
    },
    {
        language: "crs",
        name: "Seychellois Creole"
    },
    {
        language: "shn",
        name: "Shan"
    },
    {
        language: "sn",
        name: "Shona"
    },
    {
        language: "scn",
        name: "Sicilian"
    },
    {
        language: "szl",
        name: "Silesian"
    },
    {
        language: "sd",
        name: "Sindhi"
    },
    {
        language: "si",
        name: "Sinhala"
    },
    {
        language: "sk",
        name: "Slovak"
    },
    {
        language: "sl",
        name: "Slovenian"
    },
    {
        language: "so",
        name: "Somali"
    },
    {
        language: "es",
        name: "Spanish"
    },
    {
        language: "su",
        name: "Sundanese"
    },
    {
        language: "sus",
        name: "Susu"
    },
    {
        language: "sw",
        name: "Swahili"
    },
    {
        language: "ss",
        name: "Swati"
    },
    {
        language: "sv",
        name: "Swedish"
    },
    {
        language: "ty",
        name: "Tahitian"
    },
    {
        language: "tg",
        name: "Tajik"
    },
    {
        language: "ber-Latn",
        name: "Tamazight"
    },
    {
        language: "ber",
        name: "Tamazight (Tifinagh)"
    },
    {
        language: "ta",
        name: "Tamil"
    },
    {
        language: "tt",
        name: "Tatar"
    },
    {
        language: "te",
        name: "Telugu"
    },
    {
        language: "tet",
        name: "Tetum"
    },
    {
        language: "th",
        name: "Thai"
    },
    {
        language: "bo",
        name: "Tibetan"
    },
    {
        language: "ti",
        name: "Tigrinya"
    },
    {
        language: "tiv",
        name: "Tiv"
    },
    {
        language: "tpi",
        name: "Tok Pisin"
    },
    {
        language: "to",
        name: "Tongan"
    },
    {
        language: "ts",
        name: "Tsonga"
    },
    {
        language: "tn",
        name: "Tswana"
    },
    {
        language: "tcy",
        name: "Tulu"
    },
    {
        language: "tum",
        name: "Tumbuka"
    },
    {
        language: "tr",
        name: "Turkish"
    },
    {
        language: "tk",
        name: "Turkmen"
    },
    {
        language: "tyv",
        name: "Tuvan"
    },
    {
        language: "ak",
        name: "Twi"
    },
    {
        language: "udm",
        name: "Udmurt"
    },
    {
        language: "uk",
        name: "Ukrainian"
    },
    {
        language: "ur",
        name: "Urdu"
    },
    {
        language: "ug",
        name: "Uyghur"
    },
    {
        language: "uz",
        name: "Uzbek"
    },
    {
        language: "ve",
        name: "Venda"
    },
    {
        language: "vec",
        name: "Venetian"
    },
    {
        language: "vi",
        name: "Vietnamese"
    },
    {
        language: "war",
        name: "Waray"
    },
    {
        language: "cy",
        name: "Welsh"
    },
    {
        language: "wo",
        name: "Wolof"
    },
    {
        language: "xh",
        name: "Xhosa"
    },
    {
        language: "sah",
        name: "Yakut"
    },
    {
        language: "yi",
        name: "Yiddish"
    },
    {
        language: "yo",
        name: "Yoruba"
    },
    {
        language: "yua",
        name: "Yucatec Maya"
    },
    {
        language: "zap",
        name: "Zapotec"
    },
    {
        language: "zu",
        name: "Zulu"
    },
    {
        language: "he",
        name: "Hebrew"
    },
    {
        language: "jv",
        name: "Javanese"
    },
    {
        language: "zh-CN",
        name: "Chinese (Simplified)"
    }
];

export default function Appsync () {
    const {
        contentSubTitle,
        subtitleTranslationLanguage,
        isStopAutoScroll,
        isTranslateTranslatedSentences
    } = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const [mapSignalingNotifyMetadata] = useState(new Map<string, string>());
    const [client, setClient] = useState(generateClient());
    const [apiKey, setApiKey] = useState("");
    const [anchorShowSubtitleTranslation, setAnchorShowSubtitleTranslation] = React.useState<HTMLElement | null>(null);
    const openShowSubtitleTranslation = Boolean(anchorShowSubtitleTranslation);
    const [isShowSubTitleDialog, setShowSubTitleDialog] = useState(false);

    useEffect(() => {
        getAppsyncApiKey().then(apiKey => {
            setApiKey(apiKey);
        }).catch(err => {
            console.log(err);
        });

        window.onbeforeunload = function (event) {
            event.preventDefault();
            event.returnValue = "true";
            return "true";
        };

        if (!localStorage.getItem("subtitleTranslationLanguage")) {
            setShowSubTitleDialog(true);
        }

        dispatch(setTranslateTranslatedSentences(false));
    }, []);

    useEffect(() => {
        window.languageAppsync = subtitleTranslationLanguage;
        window.isTranslateTranslatedSentences = isTranslateTranslatedSentences;
    }, [subtitleTranslationLanguage, isTranslateTranslatedSentences]);

    useEffect(() => {
        const config = {
            API: {
                GraphQL: {
                    endpoint: "https://gcsswz5y6fchzp3wfpz5yf5f44.appsync-api.ap-northeast-1.amazonaws.com/graphql",
                    region: "ap-southeast-1",
                    defaultAuthMode: "apiKey",
                    apiKey
                }
            }
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        Amplify.configure(config);
        setClient(generateClient());
    }, [apiKey]);

    useEffect(() => {
        if (!apiKey) return;
        // Subscribe to new messages
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const subscription = client.graphql({query: onMessageSent, variables: {channel}}).subscribe({
            next: async (eventData: any) => {
                const newMsg = eventData.data.onMessageSent as NewMsg;
                let name: string;
                if (mapSignalingNotifyMetadata.has(newMsg.connectionId)) {
                    name = mapSignalingNotifyMetadata.get(newMsg.connectionId) || "";
                } else {
                    const metadata = await getSignalingNotifyMetadata(channel, newMsg.connectionId);
                    name = metadata.name;
                    mapSignalingNotifyMetadata.set(newMsg.connectionId, name);
                }
                const indexBackground = Array.from(mapSignalingNotifyMetadata.keys()).indexOf(newMsg.connectionId);

                const transcript = decodeHTMLandURL(newMsg.content);
                let translate;
                let translateTranslatedSentences;
                const endsWithPeriod = endsWithPeriodOrFullWidthPeriod(transcript);
                if (window.languageAppsync !== "0" && window.languageAppsync !== newMsg.language && (endsWithPeriod || newMsg.isFinal)) {
                    try {
                        const responseTranslate = await axios.post(`${process.env.REACT_APP_SESSION_API || ""}/google_translate?text=${encodeURIComponent(transcript)}&target_lang=${window.languageAppsync}&source_lang=${newMsg.language}`);
                        translate = decodeHTMLandURL(responseTranslate.data.translated_text);
                        if (window.isTranslateTranslatedSentences) {
                            const responseTranslate = await axios.post(`${process.env.REACT_APP_SESSION_API || ""}/google_translate?text=${encodeURIComponent(translate)}&target_lang=${newMsg.language}&source_lang=${window.languageAppsync}`);
                            translateTranslatedSentences = decodeHTMLandURL(responseTranslate.data.translated_text);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                const transcriptDeepGram: TranscriptDeepGram = {
                    resultsId: newMsg.resultsId,
                    connectionId: newMsg.connectionId,
                    name,
                    transcript,
                    isFinal: newMsg.isFinal,
                    isMine: false,
                    translate,
                    indexBackground,
                    translateTranslatedSentences
                };
                dispatch(setContentSubTitle(transcriptDeepGram));
            },
            error: (error: any) => {
                console.warn(error);
            }
        });

        return () => subscription.unsubscribe();
    }, [client]);

    const getSignalingNotifyMetadata = async (channelId: string, connectionId: string) => {
        const data = {
            channel_id: channelId,
            connection_id: connectionId
        };
        const response = await axios.post<SignalingNotifyMetadata>(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
            headers: {
                "x-sora-target": "Sora_20201124.GetSignalingNotifyMetadata"
            }
        });
        return response.data;
    };

    const getAppsyncApiKey = async () => {
        const response = await axios.get<AppsyncApiKeyResponse>(`${process.env.REACT_APP_SESSION_API || ""}/appsync_api_key`);
        if (response.status !== 200) {
            return "";
        }
        return response.data.api_key;
    };

    const handleClickShowSubtitleTranslation = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorShowSubtitleTranslation(event.currentTarget);
    };

    const handleCloseShowSubtitleTranslation = () => {
        setAnchorShowSubtitleTranslation(null);
    };

    useEffect(() => {
        if (!isStopAutoScroll) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [contentSubTitle]);

    return (
        <div>
            <header className="header-admin">
                <div className="logo">
                    <img src="/img/ac-s.svg" alt="Logo"/>
                </div>
                <div className="appsync-subtitle-settings">
                    <a onClick={handleClickShowSubtitleTranslation}>
                        <SettingsIcon style={{ color: "#ffffff" }} />
                    </a>
                </div>
            </header>
            <div className="admin-body">
                <div className="appsync-sub-title">
                    {
                        contentSubTitle &&
                        <div className="appsync-subtitle-list">
                            {
                                contentSubTitle.map((sub, index) => {
                                    return <SubtitleItem key={index}
                                                         name={sub.name}
                                                         content={sub.transcript}
                                                         isFinal={sub.isFinal} isMine={sub.isMine}
                                                         avatarUrl={sub.avatarUrl}
                                                         translate={sub.translate}
                                                         indexBackground={sub.indexBackground}
                                                         translateTranslatedSentences={sub.translateTranslatedSentences}/>;
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <PopoverSubtitleSetting anchorShowSubtitleTranslation={anchorShowSubtitleTranslation}
                                    handleCloseShowSubtitleTranslation={handleCloseShowSubtitleTranslation}
                                    openShowSubtitleTranslation={openShowSubtitleTranslation}
                                    isAppsyncPage={true}
                                    channelId={channel}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}/>
            <SubTitleDialog open={isShowSubTitleDialog} handleClose={() => { setShowSubTitleDialog(false); }} />
        </div>
    );
}
