import Icon from "../icon/icon";
import "./userFrame.css";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBackgroundUser, handleImageError, replaceEmoji, replaceUrlToTagA, stringFormat} from "../../utils/utils";
import {AVATAR_DEFAULT, RECVONLY} from "../../constants/constant";
import ColorThief from "colorthief";
import {type AppDispatch, type RootState} from "../../store";
import {type CustomHTMLVideoElement} from "../../services/type/customerType";
import {
    type RemoteConnection,
    removeAllIsPin,
    setIsPin,
    setShowContentChat,
    updateSpeakingTimeMine
} from "../../services/sora/soraSlice";
import {useTranslation} from "react-i18next";
import {pushChannel, pushConnection} from "../../services/sora/soraApi";
import {confirmAlert} from "react-confirm-alert";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import axios from "axios";
import {useSnackbar} from "notistack";

interface UserFrameProps {
    mediaStream?: MediaStream
    isShareScreen?: boolean
    connection?: RemoteConnection
    startPinAll?: any
    removePinAll?: any
    index: number
    width?: string
    height?: string
}

export interface MessageOnOffMicCamera {
    connectionId: string
    isMicroOn: boolean
    isCameraOn: boolean
}

let mouseStopTimeout: number | undefined;
function UserFrame (props: UserFrameProps) {
    const dispatch = useDispatch<AppDispatch>();
    const videoRef = useRef<CustomHTMLVideoElement>(null);
    const audioRef = useRef<CustomHTMLVideoElement>(null);
    const [isHasVideo, setHasVideo] = useState<boolean>(false);
    const [isHasAudio, setHasAudio] = useState<boolean>(false);
    const {
        isCameraOn,
        isMicroOn,
        messageOnOffMicCameraList,
        isMineShare,
        idConnectShared,
        showContentChat,
        connectType,
        isMeetingOwner,
        roomDetail,
        channelId
    } = useSelector((state: RootState) => state.sora);
    const {deviceUsing} = useSelector((state: RootState) => state.devices);
    const [isHasVolume, setHasVolume] = useState<boolean>(false);
    const [contentChat, setContentChat] = useState<string>("");
    const [backgroundColor, setBackgroundColor] = useState<string>("");
    const [mouseMove, setMouseMove] = useState<boolean>(false);
    const { t } = useTranslation();
    const isYourShare = isMineShare && props.connection?.mediaStreamId === idConnectShared && props.connection?.isShareScreen;
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const showVideo = async () => {
            const video = videoRef.current;
            const audio = audioRef.current;
            if (video !== null && audio != null && props.mediaStream != null) {
                video.autoplay = true;
                video.srcObject = props.mediaStream;
                audio.autoplay = true;
                audio.srcObject = props.mediaStream;
                handleMessageOnOffMicCameraList();
            }
        };
        showVideo().then(null).catch(null);
    }, [props.mediaStream]);

    useEffect(() => {
        if (props.connection?.isMine) {
            setHasVideo(isCameraOn);
        }
    }, [isCameraOn]);

    useEffect(() => {
        if (props.connection?.isMine) {
            setHasVideo(isCameraOn);
            setHasAudio(isMicroOn);
        }
    }, [isCameraOn, isMicroOn]);

    // handle message mic, camera on of off
    useEffect(() => {
        handleMessageOnOffMicCameraList();
    }, [messageOnOffMicCameraList]);

    const handleMessageOnOffMicCameraList = () => {
        const messageOnOffMicCamera = messageOnOffMicCameraList.find(messageOnOffMicCamera => messageOnOffMicCamera.connectionId === props.mediaStream?.id);
        if (messageOnOffMicCamera !== undefined) {
            setHasVideo(messageOnOffMicCamera.isCameraOn);
            setHasAudio(messageOnOffMicCamera.isMicroOn);
        }
    };

    useEffect(() => {
        if (audioRef.current && "setSinkId" in audioRef.current) {
            if (deviceUsing.speaker?.deviceId) {
                audioRef.current.setSinkId(deviceUsing.speaker.deviceId);
            } else {
                audioRef.current.setSinkId("default");
            }
        }
    }, [deviceUsing?.speaker?.deviceId]);

    // const removePin = (connectionId?: string) => {
    //     if (connectionId) {
    //         dispatch(removeIsPin(connectionId));
    //     }
    // };
    //
    // const startPin = (connectionId?: string) => {
    //     if (connectionId) {
    //         dispatch(removeAllIsPin());
    //         dispatch(setIsPin(connectionId));
    //     }
    // };

    const startAllPin = (connectionId?: string) => {
        if (connectionId) {
            dispatch(removeAllIsPin());
            dispatch(setIsPin(connectionId));
        }
        props.startPinAll(connectionId);
    };

    const removeAllPin = () => {
        dispatch(removeAllIsPin());
        props.removePinAll();
    };

    useEffect(() => {
        if (!props.mediaStream) {
            return;
        }
        if (props.mediaStream.getAudioTracks().length === 0) {
            return;
        }
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        const mediaStreamSource = audioContext.createMediaStreamSource(props.mediaStream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        mediaStreamSource.connect(analyser);

        let animationFrameId: number | null = null;
        let speakingTime = 0;
        function draw (): void {
            animationFrameId = requestAnimationFrame(draw);
            analyser.getByteTimeDomainData(dataArray);
            const array = Array.from(dataArray);
            // dataArray の最低値が 128 のため、最小値を 0 にする
            const currentVolume = Math.max.apply(null, array) - 128;
            const hasVolume = currentVolume > 0;
            setHasVolume(hasVolume);
        }
        draw();

        const timeoutAnimationFrameId = setInterval(() => {
            analyser.getByteTimeDomainData(dataArray);
            const array = Array.from(dataArray);
            // dataArray の最低値が 128 のため、最小値を 0 にする
            const currentVolume = Math.max.apply(null, array) - 128;
            const hasVolume = currentVolume > 0;
            if (hasVolume && props.connection?.mediaStreamId && props.connection?.isMine) {
                speakingTime++;
                if (speakingTime > 100) {
                    dispatch(updateSpeakingTimeMine());
                    speakingTime = 0;
                }
            }
        }, 100);

        return () => {
            if (audioContext) {
                audioContext.close().then(null).catch(null);
            }
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            clearInterval(timeoutAnimationFrameId);
        };
    }, [props.mediaStream]);

    useEffect(() => {
        if (showContentChat && (showContentChat.senderId === props.connection?.mediaStreamId || showContentChat.locationId === props.connection?.mediaStreamId)) {
            setContentChat(showContentChat.content);
            setTimeout(() => {
                setContentChat("");
                }, 5000);
            dispatch(setShowContentChat(null));
        }
    }, [showContentChat]);
    const getOrderClass = () => {
        let order;
        if (isHasVideo && isHasAudio) {
            order = 1;
        } else if (isHasVideo && !isHasAudio) {
            order = 2;
        } else if (!isHasVideo && isHasAudio) {
            order = 3;
        } else {
            order = 4;
        }
        return `order-${order}`;
    };

    useEffect(() => {
        if (props.connection?.imageUrl) {
            const colorThief = new ColorThief();

            const imageUrl = props.connection?.imageUrl;

            const image = new Image();
            image.crossOrigin = "Anonymous"; // Enable cross-origin image access if needed

            image.onload = () => {
                const primaryColor: [number, number, number] = colorThief.getColor(image);
                setBackgroundColor(`rgb(${primaryColor.join(", ")})`);
            };
            image.src = imageUrl;
        }
    }, [props.connection?.imageUrl]);

    const getStyle = () => {
        if (backgroundColor && !isHasVideo && props.connection?.imageUrl) {
            return {backgroundColor};
        }
        return {};
    };

    const getWidthHeightStyle = () => {
        if (props.width && props.height) {
            return {
                width: props.width,
                height: props.height
            };
        }
        return {};
    };
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        clearTimeout(mouseStopTimeout);
        setMouseMove(true);

        mouseStopTimeout = window.setTimeout(() => {
            setMouseMove(false);
        }, 3000);
    };

    const handleTurnOffTheMic = () => {
        const data = {
            channel_id: channelId,
            data: {
                type: "turn_off_the_mic",
                connectionId: props.connection?.connectionId
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });
    };

    const allowUserPin = () => {
        return connectType === RECVONLY || (!roomDetail?.allow_user_pin && !isMeetingOwner);
    };

    const handleRemoveFromTheCall = (connectionId?: string, name?: string) => {
        if (!connectionId || !name) {
            return;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog open={true} className="customized-dialog" fullWidth maxWidth="xs">
                        <DialogTitle>{t("confirmation")}</DialogTitle>
                        <DialogContent>
                            <span>
                                {stringFormat(t("Are you sure you want to kick out of the Session Room?"), name)}
                            </span>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { onClose(); }}>{t("Close")}</Button>
                            <Button onClick={() => { removeFromTheCall(connectionId).then(null).catch(null); onClose(); }}>OK</Button>
                        </DialogActions>
                    </Dialog>
                );
            }
        });
    };

    const removeFromTheCall = async (connectionId: string) => {
        try {
            const dataDisconnectConnection = {
                channel_id: channelId,
                connection_id: connectionId,
                reason: {
                    message: "RemoveFromTheCall"
                }
            };
            const dataPushConnection = {
                channel_id: channelId,
                connection_id: connectionId,
                data: {
                    type: "disconnect_connection"
                }
            };
            await pushConnection(dataPushConnection);
            await axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, dataDisconnectConnection, {
                headers: {
                    "x-sora-target": "Sora_20151104.DisconnectConnection",
                    "Content-Type": "application/json"
                }
            });
        } catch {
            enqueueSnackbar("Error disconnect connection");
        }
    };

    return <>
        <div className={`container-frame ${getOrderClass()} ${props.connection?.isPin ? "pin__on" : ""} ${isHasVolume && isHasAudio ? "is-has-volume" : ""}
            ${mouseMove ? "mouse-move" : ""} ${props.connection?.isMirroringWebcam ? "mirroring-webcam" : ""}`}
                 style={getWidthHeightStyle()} onMouseMove={handleMouseMove}>
            {
                (props.mediaStream !== null) &&
                <>
                    <video ref={videoRef} muted={true} playsInline={true}/>
                    <audio ref={audioRef} hidden={true} muted={props.connection?.isMine || isYourShare}/>
                </>
            }

            <div className="user-frame-action">
                <div className="action-item pin-share pin-all"
                     hidden={!props.connection?.isPin || allowUserPin()}>
                    <Icon handleOnclick={removeAllPin}
                          titleTooltip={mouseMove ? t("Reduce the screen that is stretched to everyone's screen") : ""}>
                        <img src="/img/ac-shrink.svg" alt="ac-shrink"/>
                    </Icon>
                </div>
                <div className="action-item pin-share pin-all"
                     hidden={props.connection?.isPin || allowUserPin()}>
                    <Icon handleOnclick={() => {
                        startAllPin(props.connection?.mediaStreamId);
                    }} titleTooltip={mouseMove ? t("Enlarged to fit everyone's screen") : ""}>
                        <img src="/img/ac-expansion.svg" alt="ac-expansion"/>
                    </Icon>
                </div>
                <div className="action-item" hidden={props.connection?.isMine || !isHasAudio || !isMeetingOwner}>
                    <Icon handleOnclick={handleTurnOffTheMic}
                          titleTooltip={t("Turn off the mic")}>
                        <img src="/img/ac-mic-mute.svg" alt="mic"/>
                    </Icon>
                </div>
                <div className="action-item" hidden={props.connection?.isMine || !isMeetingOwner}>
                    <Icon handleOnclick={() => { handleRemoveFromTheCall(props.connection?.connectionId, props.connection?.realName); }}
                          titleTooltip={stringFormat(t("Remove from the Session Room"), props.connection?.realName)}>
                        <img style={{ width: "30px" }} src="/img/remove_circle_outline.svg" alt="remove_circle_outline"/>
                    </Icon>
                </div>
            </div>
            <span
                className={`name-user ${props.connection?.isPin ? "name-share-none" : ""}`}>{props.connection?.realName}</span>
            {
                (contentChat !== "") && !props.connection?.isPin &&
                <div className="content-chat">
                    {
                        <span dangerouslySetInnerHTML={{__html: replaceEmoji(replaceUrlToTagA(contentChat))}}></span>
                    }
                </div>
            }
            <div
                hidden={props.connection?.isShareScreen && !isYourShare}
                style={getStyle()}
                className={`camera-mic ${!props.connection?.imageUrl || !backgroundColor ? getBackgroundUser(props.index) : ""} ${isHasVideo ? "is-has-video" : ""} ${isYourShare ? "mine_share" : ""}`}>
                {
                    !isHasVideo && !props.connection?.isShareScreen &&
                    <div className="container-avatar">
                        {
                            <img src={props.connection?.imageUrl ? props.connection.imageUrl : AVATAR_DEFAULT}
                                 onError={handleImageError}/>
                        }
                    </div>
                }
                <div className={`container-icon ${isHasVideo ? "cam__on" : "cam__off"}`}>
                    {
                        !props.connection?.isShareScreen &&
                        !isHasAudio &&
                        <Icon>
                            <img src="/img/ac-mic-mute.svg" alt="mic"/>
                        </Icon>
                    }
                </div>
            </div>
        </div>
    </>;
}

export default UserFrame;
