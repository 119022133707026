import SidebarRight from "../sidebarRight/sidebarRight";
import {setDisplaySpeakerName, setIsSubTitle} from "../../services/sora/soraSlice";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import SubtitleItem from "./subtitleItem";
import "./subtitleList.css";

export default function SubtitleList () {
    const {contentSubTitle, isStopAutoScroll} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const subTitleListRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        dispatch(setDisplaySpeakerName(true));
    }, []);

    useEffect(() => {
        if (subTitleListRef.current && !isStopAutoScroll) {
        subTitleListRef.current.scrollTop = subTitleListRef.current.scrollHeight;
        }
    }, [contentSubTitle]);

    return <SidebarRight title={t("sub-title")}
                         handleCloseClick={() => { dispatch(setIsSubTitle(false)); }}
                         hiddenDownload={true}
                         isShowSubtitle={true}>
        <div className="subtitle-list" ref={subTitleListRef}>
            {
                contentSubTitle.map((sub, index) => {
                            return <SubtitleItem key={index}
                                                 name={sub.name}
                                                 content={sub.transcript}
                                                 isFinal={sub.isFinal} isMine={sub.isMine}
                                                 avatarUrl={sub.avatarUrl}
                                                 translate={sub.translate}
                                                 indexBackground={sub.indexBackground}
                                                 translateTranslatedSentences={sub.translateTranslatedSentences} />;
                        })
            }
        </div>
    </SidebarRight>;
}
